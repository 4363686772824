import { CountryAlpha2, Currency, IANATimezone } from '@supy.api/dictionaries';

export enum CountryState {
  active = 'active',
  deleted = 'deleted',
}

export class Country {
  readonly id: string;
  readonly name: string;
  readonly code: CountryAlpha2;
  readonly state: CountryState;
  readonly cities: string[];
  readonly currency: Currency;
  readonly vat: number;
  readonly utcOffset: number;
  readonly currencyPrecision: number;
  readonly ianaTimeZone: IANATimezone;

  constructor(args: Partial<Country>) {
    this.id = args.id;
    this.name = args.name;
    this.code = args.code;
    this.state = args.state;
    this.cities = args.cities;
    this.currency = args.currency;
    this.vat = args.vat;
    this.utcOffset = args.utcOffset;
    this.currencyPrecision = args.currencyPrecision;
    this.ianaTimeZone = args.ianaTimeZone;
  }
}
